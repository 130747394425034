import {graphql, Link, StaticQuery} from "gatsby"
import React from "react"
import {styled} from "../styles/theme"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPhone, faEnvelope, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons"
import {faFacebook, faGithub, faLinkedin, faYoutube} from "@fortawesome/free-brands-svg-icons"

type FooterLink = {
  title: string
  href: string
}

type StaticQueryData = {
  site: {
    siteMetadata: {
      copyright: string
      social: {
        linkedin: string
        github: string
        facebook: string
        youtube: string
      }
      contact: {
        title: string
        phone: string
        email: string
        address: string
      }
      footer: {
        title: string
        links: Array<FooterLink>
      }
    }
  }
}

const StyledFooter = styled.footer`
  padding-bottom: 36px;
`

const Footer: React.FC = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            copyright
            social {
              linkedin
              facebook
              github
              youtube
            }
            contact {
              title
              email
              phone
              address
            }
            footer {
              title
              links {
                title
                href
              }
            }
          }
        }
      }
    `}
    render={(data: StaticQueryData): React.ReactElement | null => {
      const {copyright, contact, social, footer} = data.site.siteMetadata
      return (
        <footer className="w-full px-6 md:px-12 pt-8 bg-tercen-footer">
          <div className="container flex flex-col md:flex-row">
            <div className="md:w-5/12 ">
              <h3 className="text-2xl pt-2 pb-4 font-bold md:pt-3 md:pb-6">{footer.title}</h3>
              {footer.links.map((link: FooterLink) => {
                return (
                  <a className="flex py-1 md:py-2 font-extralight text-sm" href={link.href} key={link.title}>
                    {link.title}
                  </a>
                )
              })}
            </div>
            <div className="pt-5 md:pt-0 md:w-5/12">
              <h3 className="text-2xl pt-2 pb-4 font-bold md:pt-3 md:pb-6">{contact.title}</h3>
              <p className="py-1 md:py-3 text-sm font-extralight">
                <FontAwesomeIcon icon={faPhone} className="text-sm" />
                <a href={`tel:${contact.phone}`} className="pl-2">
                  {contact.phone}
                </a>
              </p>
              <p className="py-1 md:py-3 text-sm font-extralight">
                <FontAwesomeIcon icon={faEnvelope} className="text-sm" />
                <a href={`mailto:${contact.email}`} className="pl-2">
                  {contact.email}
                </a>
              </p>
            </div>
            <div className="pt-6 md:pt-0 md:w-2/12 text-3xl md:text-right">
              <a className="pr-2" href={social.linkedin} target="_blank">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a className="pr-2" href={social.github} target="_blank">
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a className="pr-2" href={social.facebook} target="_blank">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a className="" href={social.youtube} target="_blank">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>
          <div className="container border-t border-gray-300 py-3 mt-4 md:mt-5 font-extralight text-sm">
            <p>
              {copyright} - Tercen Data Analytics Ltd. &middot; c/o David Breen & Company, Unit 3 Wallace House -
              Maritana Gate,Canada Street, Waterford, Ireland. X91CR9X
            </p>
          </div>
        </footer>
      )
    }}
  />
)

export default Footer
