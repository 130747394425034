import styled, { css, createGlobalStyle } from 'styled-components'

export { css, styled }

export const theme = {}

const reset = () => ``

const styles = () => `
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,300;1,300&display=swap');
`

export const GlobalStyle = createGlobalStyle`
${reset()}
${styles()}
`
