import React from "react"
import {GlobalStyle} from "../styles/theme"
import Footer from "./footer"
import Head from "./head"
import Navbar from "./navbar"
import CookieConsent from "react-cookie-consent"

interface Props {
  readonly title?: string
  readonly keywords?: string[]
  readonly description?: string
  readonly hideNavbar?: boolean
  readonly hideMenu?: boolean
  readonly children: React.ReactNode
}

const Layout: React.FC<Props> = ({title, keywords, description, hideNavbar, hideMenu, children}) => (
  <div className="flex flex-col h-screen justify-between">
    <Head title={title} keywords={keywords} description={description}/>
    <GlobalStyle />
    {!hideNavbar && <Navbar hideMenu={hideMenu} />}
    <main role="main" className="antialiased mb-auto">
      {children}
    </main>
    <Footer />
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-tagmanager"
      style={{background: "#76bbff"}}
      contentClasses="text-xs px-3"
      buttonClasses="bg-tercen-yellow text-xs p-2 rounded-2">
      We use cookies in order to improve your experience as well as for analytics purposes.{" "}
      <a href="http://www.tercen.com">More info here</a>.
    </CookieConsent>
  </div>
)

export default Layout
